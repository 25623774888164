html, body {
  overflow-x: hidden!important;
  }

.sideDrawer{
  width: 30px;
  color: #9b047a;
}
.titleClass{
  color: #050505;
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
}

/*header css*/
.main{
  background-color: hsl(0, 25%, 98%);
  height: 68px;
}
.main h1 span{
  color: hsl(0, 25%, 98%);
}

#mainimg{
  padding-bottom: 22px;
  width:auto;
  height: 750px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #ffffff;
  background-image: url("images/background2.jpg");
}

 .name{
  font-family: 'Montserrat';
  color: #070707;
  width: 500px;
  position: absolute;
    left: 50%;
    text-align: center;
  top: 50px;
  transform: translate(-50%,-50%);
  }
  .name span{
    color: #f4f3f7;
    border-bottom: 2px solid #0a0a0a;
  }
  .name .details{
    color: hsla(0, 0%, 5%, 0.8);
    font-size: 20px;
  }
  .s-box .details{
    font-size: 20px;
    margin-left: 10px;
  }
  .name h1{
  font-family:'Montserrat';
  font-size: 46px;
  margin:0px;
  letter-spacing: 3px;
  color:#ffffff;
  }

  .header-btns{
    display: flex;
    margin-top: 40px;
    margin-left: 6.8em;
  }
  .s-heading{
    text-align: center;
    margin: 10px 0px;
  }

  .s-heading h1{
    font-size: 2.5rem;
    color: #050505;
  }
  .content{
    padding-bottom: 1.5rem;    /* Footer height */
    background-color: #f7f0f0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .s-heading{
    background-color: #f7f0f0;
    margin-top: 2px;
  }
  #about{
    color:rgba(10, 10, 10, 0.911);
    width:100%;
    height:auto;
    background-color:#f7f0f0;
    box-sizing: border-box;
    font-family: calibri;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #about h1{
    color: #3f04ac;
    padding-top: 40px;
  }
  .profile-p{
    max-width: 500px;
    margin: auto;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
  }
  #about .profile-p{
    color:rgba(19, 18, 18, 0.911);
    justify-content: left;
  }
  .typographyText{
    align-items: center;
  }
  #contact{
    margin-top: 0px;
    width:100%;
    height:100vh;
    background-color:#f7f0f0;
    box-sizing: border-box;
    font-family: calibri;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px ;
  }
  #contact h1{
    color: #3f04ac;
  }
  #services{
    margin-top: 0px;
    width:100%;
    height:100%;    
    background-color:#f7f0f0;
    box-sizing: border-box;
    font-family: calibri;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px ;
  }
  #services h1{
    color: #3f04ac;
  }

.active{
  background-color: #9b047a;
  color:hwb(0 92% 8%);
  box-shadow: 5px 10px 30px rgba(250, 160, 235, 0.411);
  transition: all ease 0.2s;
}

.footer{
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  height: 1.5rem;            /* Footer height */

}

@media(max-width:1190px){
  #main{
    background-size: 100% !important;
    width: auto;
  }
  .name{
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .header-btns{
    margin-left: 10em;
  }
  
  .features-model img{
    height:400px;
    }
    #services{
    height:auto;
    }
    #about{
      height: auto;
    }
    #contact{
      height: auto;
    }
    .b-container{
    flex-wrap: wrap;
  }
    .s-box{
    flex-grow: 1;
  }
  .s-box1{
    flex-grow: 1;
  }
  .s-b-img img{
    object-fit:cover;
  }
  .s-box .s-b-text{
    height: fit-content;
  }
  
  }
  .logodiv {
    display: none;
  }
  
  @media(max-width:970px){
    #main{
      background-size: 970px !important;
      margin-left: -115px;
      width: auto;
    }
    #services{
      height: auto;
    }
  .features-model{
    display: none;
  }
  .s-heading h1{
    font-size: 2.5rem;
    color: #9b047a;
  }
  .features-text h2{
    font-weight: 600;
  }
  .b-container{
    margin-top: 30px;
  }
  .s-box .s-b-text{
    height: fit-content;
  }
  .contactIconsOnFooter{
    margin-top: 0px;
    display:flex;
    justify-content:center;
    align-items: center;
    color: #fff;
    text-align: center;
    flex-direction:column;
  }
  .whatsAppF{
    color: rgb(20, 85, 34);
    text-shadow: 1px 1px 1px rgb(5, 71, 38);
    font-size: 30px;
  }
  .phoneF{
    color: rgb(250, 241, 247);
    text-shadow: 1px 1px 1px rgb(5, 71, 38);
    font-size: 30px;
  }
  .envelopeF{
    color: rgb(68, 7, 233);
    text-shadow: 1px 1px 1px rgb(5, 71, 38);
    font-size: 30px;
  }
  .logodiv {
    display: block;
    padding-bottom: 0%;
  }
  }

  /*Very smaill screens*/
  @media screen and (min-width:100px) and (max-width:300px) {
    #about{
      height: auto;
    }
    .logo{
      width: 20px;
    }
  }
  
  /*Samsung galaxy*/
  @media screen and (min-width:100px) and (max-width:780px) {
    
    #main{
      background-size: 100% !important;
      width: auto;
      height: 60px;
    }
    #main h1{
      color: #9b047a;
      
    }
    #main h1 span{
      font-size: 30px;
      
    }
  .name{
    width:60%;
    top: 570px;
    }
    #mainimg .name h1 span{
      color: #3f04ac;
      font-family: 'Times New Roman', Times, serif;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: normal;
    }
    .active,
    .services,
    .about,
    .contact{
      text-align: center;
      
      width: 100%;
      transition: all 0.5s ease;
    }
    .logoH {
      width: 140px;
      height: 160px;
    }
    .details-contact-us{
      font-size:medium;
    }
    #mainimg{
      background-size: 100% !important;
      width: auto;
      height: 580px;
    }
    #mainimg .name{
      top: 500px;
    }
}

/* ipad Mini Landscape */
@media screen and (min-width:768px) and (max-width:1702px) {
  #main{
    background-size:100% !important;
  }
  .logodiv {
    display: none;
    padding-bottom: 0%;
  }
  #main h1{
    color: #9b047a;
    
  }
  #main h1 span{
    font-size: 30px;
    
  }
.name{
  width:60%;
  top: 580px;
  }
  .nav-cont .active{
    color: #fff;
      text-align: center;
      
      width: 100%;
      transition: all 0.5s ease;
  }
  .nav-cont .about{
      text-align: center;
      width: 100%;
      transition: all 0.5s ease;
    }
    .nav-cont .services{
      color: #fff;
      text-align: center;
      width: 100%;
      transition: all 0.5s ease;
    }
    .nav-cont .contact{
      color: #fff;
      text-align: center;
      width: 100%;
      transition: all 0.5s ease;
    }

}
    .scroll{
        left: 42.5%;
    }
        @media(max-width:400px){
            .s-box{
                width: 100%;
                height:450px;
            }
            .s-box3{
              width: 100%;
              height:450px;
          }
          .s-box .s-b-text{
            height: fit-content;
          }
        }

@media only screen and (-webkit-min-device-pixel-ratio: 2.5) {
          /* CSS for Microsoft surface Duo*/
          #main{
            background-size:100% !important;
          }
        }
