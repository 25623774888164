/*ContactBox1*/
.phoneIcon{
    color: #470202;
}

.footer-bottom {
    text-align: center;
    padding-top: 10px;
    height: 1rem;
    left: 0%;
    right: 0%;
    padding: 30px;
    width: 100%;
  }